import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faChevronDown from "@fortawesome/fontawesome-free-solid/faChevronDown";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PostFeaturedImage from "../components/PostFeaturedImage";
import Video from "../components/Video";
import ReadMoreHTML from "../components/ReadMoreHTML";
import EntryHeader from "../components/Headers/Entry";
import ContentTheme from "../components/ContentTheme";
import InsetContent from "../components/InsetContent";
import { TagList, Tag } from "../components/TagList";
import { rhythm } from "../utils/typography";
import SiteNav from "../components/SiteNav";
import SiteFooter from "../components/SiteFooter";
import Sections from "../components/Headers/Sections";

const Icon = styled(FontAwesomeIcon)`
	height: 0.5em;
	position: relative;
	top: -0.25em;

	margin-left: 10px;
	margin-right: 10px;
	animation: ${(props) =>
			props.vertical
				? "wiggle-vertical"
				: props.animated
				? "wiggle-horizontal"
				: "none"}
		ease-in-out 4s;
	animation-iteration-count: infinite;
	transform-origin: 50% 50%;

	@keyframes wiggle-vertical {
		0% {
			transform: translate(0px, 0px);
		}
		10% {
			transform: translate(0px, 5px);
		}
		20% {
			transform: translate(0px, 0px);
		}
		30% {
			transform: translate(0px, 5px);
		}
		40% {
			transform: translate(0px, 0px);
		}
		100% {
			transform: translate(0px, 0px);
		}
	}

	@keyframes wiggle-horizontal {
		0% {
			transform: translate(0px, 0px);
		}
		10% {
			transform: translate(3px, 0px);
		}
		20% {
			transform: translate(0px, 0px);
		}
		30% {
			transform: translate(3px, 0px);
		}
		40% {
			transform: translate(0px, 0px);
		}
		100% {
			transform: translate(0px, 0px);
		}
	}
`;

const HeaderImage = styled(Img)`
	height: 7em;
`;

const Article = styled.article`
	background: ${(props) => props.theme.contentBackground};
	padding-top: ${(props) => (props.image ? "0" : "2em")};
`;

const PostContent = styled(ReadMoreHTML)`
	color: ${(props) => props.theme.contentColor};
`;

const TabletopCampaignPreface = styled.div`
	margin-top: 2em;
`;

const TabletopCampaignTitle = styled.h1`
	display: block;
	text-align: center;
	padding-bottom: 1em;
	padding-top: 1em;
	background: ${(props) => props.theme.navigationBarBackground};
	border-top: ${(props) => props.theme.navigationBarBorder};
	border-bottom: ${(props) => props.theme.navigationBarBorder};
`;

class TabletopCampaign extends React.Component {
	render() {
		const siteTitle = get(this, "props.data.site.siteMetadata.title");
		const tabletopCampaign =
			get(this, "props.data.campaign") ||
			get(this, "props.pageContext.campaign");
		const tabletopCampaignTitle = get(
			tabletopCampaign,
			"frontmatter.title"
		);
		const posts = get(this, "props.data.posts.edges") || [];
		const image = get(this, "props.data.header");
		const permalink = get(tabletopCampaign, "frontmatter.permalink");
		return (
			<Layout>
				<ContentTheme>
					<div>
						{permalink && (
							<Helmet key="permalink">
								<link
									rel="self"
									href={"https://stevestreza.com" + permalink}
								/>
							</Helmet>
						)}
						<SiteNav />
						{image && (
							<PostFeaturedImage
								height={"7em"}
								image={image.childImageSharp.gatsbyImageData}
							/>
						)}
						<Sections />
						<Helmet
							title={
								tabletopCampaignTitle
									? tabletopCampaignTitle + " - " + siteTitle
									: siteTitle
							}
						/>
						{tabletopCampaign &&
							tabletopCampaign.html &&
							tabletopCampaign.frontmatter.permalink && (
								<TabletopCampaignPreface>
									<InsetContent>
										<h1>{tabletopCampaignTitle}</h1>
										<PostContent
											html={tabletopCampaign.html}
											slug={
												tabletopCampaign.frontmatter
													.permalink
											}
										/>
									</InsetContent>
								</TabletopCampaignPreface>
							)}
						{tabletopCampaignTitle && (
							<TabletopCampaignTitle>
								Campaign Diaries{" "}
								<Icon
									icon={faChevronDown}
									animated={true}
									vertical={true}
								/>
							</TabletopCampaignTitle>
						)}
						{posts.map(({ node }) => {
							const title =
								get(node, "frontmatter.title") ||
								node.fields.slug;
							return (
								<ContentTheme
									key={node.id}
									layout={node.frontmatter.layout}
								>
									<Article
										layout={
											node.frontmatter.layout || "post"
										}
										className={
											"layout-" +
											(node.frontmatter.layout || "post")
										}
										key={node.fields.slug}
										image={node.frontmatter.image}
									>
										<EntryHeader
											slug={node.fields.slug}
											title={title}
											date={node.frontmatter.date}
											link={node.frontmatter.link}
											image={
												node.frontmatter.image &&
												node.frontmatter.image
													.childImageSharp
													.gatsbyImageData
											}
										/>
										<InsetContent>
											{node.frontmatter.video_url && (
												<section>
													<Video
														url={
															node.frontmatter
																.video_url
														}
													/>
												</section>
											)}
											<PostContent
												className="e-content"
												html={node.html}
												slug={node.fields.slug}
												link={node.frontmatter.link}
											/>
											{node.frontmatter.tags && (
												<TagList>
													{node.frontmatter.tags.map(
														(tag) => (
															<Tag
																key={tag}
																tag={tag}
															/>
														)
													)}
												</TagList>
											)}
										</InsetContent>
									</Article>
								</ContentTheme>
							);
						})}
						<SiteFooter />
					</div>
				</ContentTheme>
			</Layout>
		);
	}
}

TabletopCampaign.propTypes = {
	pageContext: PropTypes.shape({
		campaign: PropTypes.string.isRequired,
	}),
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
						}),
					}),
				}).isRequired
			),
		}),
	}),
};

export const query = graphql`
	query TabletopCampaignPage($campaign: String) {
		site {
			siteMetadata {
				title
			}
		}

		header: file(relativePath: { eq: "header/header.png" }) {
			...ImageData
		}

		campaign: markdownRemark(
			frontmatter: { type: { eq: "campaign" }, title: { eq: $campaign } }
		) {
			frontmatter {
				title
				status
				permalink
			}
			html
		}

		posts: allMarkdownRemark(
			limit: 20
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { campaign: { eq: $campaign } } }
		) {
			edges {
				node {
					id
					html
					fields {
						slug
					}
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						dateISO8601: date(
							formatString: "YYYY-MM-DD HH:mm:ss.SSSZZ"
						)
						title
						video_url
						layout
						link
						tags
						image {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
				}
			}
		}
	}
`;

export default TabletopCampaign;
